export default function initFilterModel () {
  return {
    search : '',
    action : "admin",
    parentId: null,
    lan: "uz_k",
    page: {
      page: 1,
      size: 10,
      sort: {
        col: 'id',
        dir: 'desc'
      }
    }
  }
}